import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import StatusCardComponent from '../../components/StatusCard/StatusCard.component';

const Status = (props: any) => {
  const devices = useSelector((state: any) => state.devices);
  return (
    <Fragment>
      <div className='grid'>
        {devices?.data?.length > 0 ? (
          devices.data.map((device: any) => {
            return (
              <div key={device.address} className='col-desk-3 col-mob-4'>
                <StatusCardComponent data={device} />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </Fragment>
  );
};

export default Status;
