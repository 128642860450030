import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

// const disableReactDevTools = (): void => {
//   const noop = (): void => undefined;
//   const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

//   if (typeof DEV_TOOLS === 'object') {
//     for (const [key, value] of Object.entries(DEV_TOOLS)) {
//       DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
//     }
//   }
// };

// if (process.env.NODE_ENV === 'production') disableReactDevTools();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
