import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceByHash, registerDevice } from '../../store/actions';

import styles from './registration.module.scss';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MapComponent from '../../components/Map/Map.component';

const Registration = (props: any) => {
  const dispatch = useDispatch();
  const device = useSelector((state: any) => state.current);
  const [value, setValue] = useState({ name: '' });
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchDeviceByHash(props.match.params.hash));
  }, [dispatch, props]);

  const handleChange = (event: { target: { value: any } }) => {
    if (event.target.value.length <= 3) return;
    setValue({ name: event.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch(registerDevice({ _id: device.data._id, name: value.name, coordinates: device.data.coordinates }));
  };

  return (
    <Fragment>
      {device && device.data && device.data.active === false ? (
        <>
          <div className={styles['title']}>{t('Registration_title')}</div>
          <form onSubmit={handleSubmit} style={{ maxWidth: '450px', margin: '50px auto' }}>
            <input
              name='name'
              type='text'
              className={styles['f-input']}
              placeholder={t('Registration_name')}
              onChange={handleChange}
            />{' '}
            <MapComponent />
            <input
              name='email'
              type='text'
              className={styles['f-input']}
              placeholder='Email'
              value={device && device.data ? device.data.email : ''}
              disabled={true}
            />
            <input
              name='mac'
              type='text'
              className={styles['f-input']}
              placeholder='MAC ADDRESS'
              value={device && device.data ? device.data.address : ''}
              disabled={true}
            />{' '}
            <button
              className={styles['button']}
              type='submit'
              value='Send'
              disabled={value.name.length <= 3 ? true : false}
            >
              {t('Registration_action')}
            </button>
          </form>
        </>
      ) : (
        <>
          <div className={styles['title']}>{t('Registration_registered')}</div>
          {device && device.data && device.data.active === true ? (
            <Redirect to={`/details/${device.data.address}`}></Redirect>
          ) : (
            <></>
          )}
        </>
      )}
    </Fragment>
  );
};

export default Registration;
