import React, { Fragment } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

const ConsentComponent = (props: any) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <CookieConsent
        location='bottom'
        buttonText={t('Cookies_understand')}
        cookieName='respira_cookie_consent'
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        {t('Cookies_text')}{' '}
      </CookieConsent>
    </Fragment>
  );
};

export default ConsentComponent;
