import axios from '../../services/axios.service';

const handleDays = (date: { setDate: (arg0: any) => void; getDate: () => any }, days: any) => {
  const newDate = date.setDate(date.getDate() + days);
  return Number(newDate);
};

export const FETCH_BY_RANGES = 'FETCH_BY_RANGES';
export function fetchByRanges(param: string, range: string) {
  let start;
  let end;

  switch (range) {
    case 'week':
      start = new Date(handleDays(new Date(), -7)).toISOString();
      end = new Date().toISOString();

      break;

    case 'month':
      start = new Date(handleDays(new Date(), -30)).toISOString();
      end = new Date().toISOString();
      break;

    default:
      break;
  }

  return {
    type: FETCH_BY_RANGES,
    payload: range !== '24h' ? axios.get(`/v1/measures/${param}/${start}/${end}`) : axios.get(`/v1/measures/${param}`),
  };
}

export const ADD_MEASURE = 'ADD_MEASURE';
export function addMeasure(measure: any) {
  return {
    type: ADD_MEASURE,
    payload: { data: measure },
  };
}
