import { FETCH_DEVICE_HASH, REGISTER_DEVICE, SET_CURRENT_DEVICE, SET_DEVICE_COORDINATES } from '../actions';

export default function (state: any = {}, action: { type: string; payload: { data: any } }) {
  switch (action.type) {
    case FETCH_DEVICE_HASH:
    case REGISTER_DEVICE:
    case SET_CURRENT_DEVICE:
      const data = action.payload.data;
      return { ...state, data };

    case SET_DEVICE_COORDINATES:
      const newObject = Object.assign({}, state.data, { coordinates: action.payload.data });
      return { ...state, ...{ data: newObject } };

    default:
      return state;
  }
}
