import React, { Fragment, useEffect, useState } from 'react';
import styles from './map.module.scss';

import { useGoogleMaps } from 'react-hook-google-maps';
import { useDispatch } from 'react-redux';
import { setDeviceCoordinates } from '../../store/actions';

const MapComponent = (props: any) => {
  const [position, setPosition] = useState({ coords: { latitude: 40.4167754, longitude: -3.7037902 } });

  const dispatch = useDispatch();

  const { ref, map, google } = useGoogleMaps('AIzaSyA40PDAwkdYNsCOgv_PSOFmJ9lSnDOHM_w', {
    center: { lat: 40, lng: -2.5 },
    zoom: 5,
  });

  useEffect(() => {
    if (map && google) {
      const success = (position: any) => {
        setPosition(position);
        const marker = new google.maps.Marker({
          position: { lat: position.coords.latitude, lng: position.coords.longitude },
          map,
          draggable: true,
        });

        google.maps.event.addListener(marker, 'dragend', (e: any) => {
          dispatch(setDeviceCoordinates([e.latLng.lat(), e.latLng.lng()]));
        });
      };

      const error = (error: any) => {
        console.log('Wrrr ', error);
        const marker = new google.maps.Marker({
          position: { lat: position.coords.latitude, lng: position.coords.longitude },
          map,
          draggable: true,
        });

        google.maps.event.addListener(marker, 'dragend', (e: any) => {
          dispatch(setDeviceCoordinates([e.latLng.lat(), e.latLng.lng()]));
        });
      };

      navigator.geolocation.getCurrentPosition(success, error);
    }
    // eslint-disable-next-line
  }, [map]);

  return (
    <Fragment>
      <div ref={ref} className={styles['map']} />
    </Fragment>
  );
};

export default MapComponent;
