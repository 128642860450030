import axios from '../../services/axios.service';

export const FETCH_DEVICES = 'FETCH_DEVICES';
export function fetchDevices(email: string) {
  const request = axios.get(`/v1/devices/${email}`);
  return {
    type: FETCH_DEVICES,
    payload: request,
  };
}

export const FETCH_DEVICE_HASH = 'FETCH_DEVICE_HASH';
export function fetchDeviceByHash(hash: string) {
  const request = axios.get(`/v1/devices/hash/${hash}`);
  return {
    type: FETCH_DEVICE_HASH,
    payload: request,
  };
}

export const REGISTER_DEVICE = 'REGISTER_DEVICE';
export function registerDevice({ _id, name, coordinates }: { _id: string; name: string; coordinates: number[] }) {
  const request = axios.patch(`/v1/devices/${_id}/activate`, { name: name, coordinates: coordinates, active: true });
  return {
    type: REGISTER_DEVICE,
    payload: request,
  };
}

export const SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE';
export function setCurrentDevice(mac: any) {
  const request = axios.get(`/v1/devices/mac/${mac}`);
  return {
    type: SET_CURRENT_DEVICE,
    payload: request,
  };
}

export const SET_DEVICE_COORDINATES = 'SET_DEVICE_COORDINATES';
export function setDeviceCoordinates(coordinates: number[]) {
  return {
    type: SET_DEVICE_COORDINATES,
    payload: { data: coordinates },
  };
}
