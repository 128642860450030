import React, { Fragment, useState } from 'react';
import styles from './statusCard.module.scss';
import { co2Limits } from '../../interfaces';
import { QRCode } from 'react-qr-svg';
import { Overlay } from 'react-portal-overlay';

const StatusCardComponent = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = props;

  const colors = {
    danger: '#BD4126',
    alert: '#E9D15B',
    warning: '#D7884B',
    success: '#A2D74B',
  };

  return (
    <Fragment>
      <Overlay open={isOpen} onClose={() => setIsOpen(false)} closeOnClick className={styles['modal-container']}>
        <div className={styles['modal-content']}>
          <h1>{data.name}</h1>
          <p>
            <QRCode
              bgColor='#FFFFFF'
              fgColor='#000000'
              level='Q'
              style={{ width: 256 }}
              value={`https://respira-indoor.inubo.es/details/${data.address}`}
            />
          </p>
        </div>
      </Overlay>
      <div
        className={styles['container']}
        style={
          data.lastData.co2 < co2Limits.success
            ? { backgroundColor: colors.success }
            : data.lastData.co2 < co2Limits.warning
            ? { backgroundColor: colors.success }
            : { backgroundColor: colors.danger }
        }
      >
        <div className={styles['title']}>{data.name}</div>
        <div className={styles['values']}>
          <span>{data.lastData.temperature} ºC</span>
          <span>{data.lastData.humidity} %</span>
          <span>{data.lastData.co2} ppm</span>
        </div>

        <div className={styles['qr-link']} onClick={() => setIsOpen(true)}>
          QR Code
        </div>
      </div>
    </Fragment>
  );
};

export default StatusCardComponent;
