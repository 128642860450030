import { ADD_MEASURE, FETCH_BY_RANGES } from '../actions';

export default function (state: any = {}, action: { type: any; payload: { data: any } }) {
  switch (action.type) {
    case FETCH_BY_RANGES:
      const data = action.payload.data;
      return { ...state, data };

    case ADD_MEASURE:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };

    default:
      return state;
  }
}
