import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ConsentComponent from './components/Consent/Consent.component';
// import CookieConsent from 'react-cookie-consent';
import Registration from './containers/Registration/Registration.container';
import Status from './containers/Status/Status.container';
// import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('./containers/Home/Home.container'));
const NotFound = lazy(() => import('./containers/NotFound/NotFound.container'));
const MenuBar = lazy(() => import('./containers/MenuBar/MenuBar.container'));
const Footer = lazy(() => import('./containers/Footer/Footer.container'));
const Details = lazy(() => import('./containers/Details/Details.container'));

// const { t } = useTranslation();

const AppRouter = () => (
  <BrowserRouter>
    <Suspense fallback={<div></div>}>
      <MenuBar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/status' component={Status} />
        <Route path='/details/:device' component={Details} />{' '}
        <Route path='/registration/:hash' component={Registration} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
      <ConsentComponent />
    </Suspense>
  </BrowserRouter>
);

export default AppRouter;
