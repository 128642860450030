import { combineReducers } from 'redux';

import CurrentDeviceReducer from './currentDevice.reducer';
import DevicesReducer from './devices.reducer';
import MeasuresReducer from './measures.reducer';

const rootReducer = combineReducers({
  measures: MeasuresReducer,
  devices: DevicesReducer,
  current: CurrentDeviceReducer,
});

export default rootReducer;
